<template>
  <div>
  </div>
</template>
<script>

export default {
  name: 'Logout',
  title: ' | Log out',
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('logout')
      .then(async (response) => {
        await this.$store.dispatch('clientCredential')
        this.$router.push({ name: 'home' })
      })
      .catch(() => {
        this.$router.push({ name: 'home' })
      })
  }
}
</script>
<style scoped>

</style>
